import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import Heart from '../../assets/heart.png'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {
    const transition = {type : 'spring', duration : 3}
  return (
    <div className='hero' id='hero'>
        <div className="blur hero-blur"></div>
        <div className='left-h'>
            <Header/>
            {/* The best ad */}
            <div className="the-best-ad">
                <motion.div  
                initial={{left:'130px'}}
                whileInView={{left:'8px'}}
                transition={{...transition,type:'tween' }}>
                </motion.div>
                <span>The Best Fitness Club</span>
            </div>  
            {/* Hero Text */}
            <div className='hero-text'>
                <div>
                <span className='stroke-text'>Shape</span>
                <span>Your</span>
                </div>
            <div ><span>Ideal Body</span></div>
            <div><span>IN HERE WE WILL HELP YOU TO SHAPE & BUILD YOUR IDEAL BOFY & LIVE UP YOUR LIFE TO FULLEST</span></div>
            </div>
            {/* Figures */}
            <div className='figures '>
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay="8" preFix="+"/>
                    </span>
                    <span>Expert Coaches</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={979} start={950} delay="80" preFix="+"/>
                    </span>
                    <span>Members Joined</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={50} start={38} delay="8" preFix="+"/>
                    </span>
                    <span>Fitness Programs</span>
                </div>
            </div>
            {/* Hero Button */}
            <div className='hero-buttons'>
                <button className='btn'>Get Started</button>
                <button className='btn'>Learn More</button>

            </div>

        </div>
        <div className='right-h'>
            <button className='btn'>Join Now</button>

            <motion.div 
                className='heart-rate'
                initial={{right:'-1rem'}}
                whileInView={{right:'4rem'}}
                transition={transition}>
                <img src = {Heart}/>
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>

            {/* Hero Images */}
            <img src={hero_image} alt="" className='hero-image' />
            <motion.img 
            initial={{right:'11rem'}}
            whileInView={{right:'25rem'}}
            transition={transition}
            src={hero_image_back} alt="" className='hero-image-back'/>

            {/* Calories */}
            <motion.div 
                className='calories'
                initial={{right:'45rem'}}
                whileInView={{right:'37rem'}}
                transition={transition}>
                <img src={Calories} alt="" />
                <div>
                    <span>Calories Burnend</span><span>220 kcal</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default Hero