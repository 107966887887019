import React, { useState } from 'react';
import './Header.css';
import Bars from '../../assets/bars.png';
import Logo from '../../assets/logo.png';
import { Link } from 'react-scroll';

const Header = () => {
  const mobile = window.innerWidth <= 768;
  const [menu, setMenu] = useState(false);

  return (
    <div className='header'>
      <img className='logo' src={Logo} alt='Logo' />
      {menu === false && mobile === true ? (
        <div
          onClick={() => setMenu((prev) => !prev)}
          style={{
            backgroundColor: 'var(--appColor)',
            padding: '0.5rem',
            borderRadius: '5px'
          }}
        >
          <img src={Bars} style={{ width: '1.5rem', height: '1rem' }} alt="Menu"/>
        </div>
      ) : (
        <div>
          <ul className='header-menu'>
            <li>
              <Link
                to='hero'
                spy={true}
                smooth={true}
                onClick={() => setMenu(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to='programs'
                spy={true}
                smooth={true}
                onClick={() => setMenu(false)}
              >
                Programs
              </Link>
            </li>
            <li>
              <Link
                to='reasons'
                spy={true}
                smooth={true}
                onClick={() => setMenu(false)}
              >
                Why Us
              </Link>
            </li>
            <li>
              <Link
                to='plans'
                spy={true}
                smooth={true}
                onClick={() => setMenu(false)}
              >
                Plans
              </Link>
            </li>
            <li>
              <Link
                to='testimonials'
                spy={true}
                smooth={true}
                onClick={() => setMenu(false)}
              >
                Testimonials
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
